import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

const schedule = [
    ["09:00 - 10:30", "Математика", "Физика", "Информатика", "Математика", "Философия", ""],
    ["10:40 - 12:10", "Физика", "Информатика", "Математика", "Философия", "Физика", ""],
    ["12:40 - 14:10", "Информатика", "Математика", "Физика", "Математика", "Информатика", ""],
    ["14:30 - 16:00", "Философия", "Физика", "Математика", "Информатика", "Математика", ""],
    ["16:10 - 17:40", "", "", "", "", "", ""],
    ["17:50 - 19:30", "", "", "", "", "", ""],
];

const Schedule = () => {
    const currentTime = new Date().getHours();
    const currentPairIndex = currentTime >= 9 && currentTime < 19 ? Math.floor((currentTime - 9) / 2) : -1;

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Расписание
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Время</TableCell>
                        <TableCell>Пн</TableCell>
                        <TableCell>Вт</TableCell>
                        <TableCell>Ср</TableCell>
                        <TableCell>Чт</TableCell>
                        <TableCell>Пт</TableCell>
                        <TableCell>Сб</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schedule.map((row, rowIndex) => (
                        <TableRow key={rowIndex} sx={currentPairIndex === rowIndex ? { backgroundColor: "#f0f0f0" } : {}}>
                            {row.map((cell, cellIndex) => (
                                <TableCell key={cellIndex}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default Schedule;
