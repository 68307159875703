import React, { useState } from 'react';
import { CssBaseline, Box, AppBar, Toolbar, Typography, IconButton, Switch } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Sidebar from './components/Sidebar';
import ImportantInfo from './pages/ImportantInfo';
import Schedule from './pages/Schedule';
import Materials from './pages/Materials';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';

function App() {
    const [darkMode, setDarkMode] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true); // состояние для открытия/закрытия Sidebar

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen); // логика для скрытия/отображения Sidebar
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={toggleSidebar} sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Учебный портал {/* Заголовок названия системы */}
                        </Typography>
                        <IconButton color="inherit" onClick={toggleDarkMode}>
                            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        <Switch checked={darkMode} onChange={toggleDarkMode} />
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex' }}>
                    {sidebarOpen && <Sidebar />}
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Routes>
                            <Route path="/" element={<ImportantInfo />} />
                            <Route path="/important-info" element={<ImportantInfo />} />
                            <Route path="/schedule" element={<Schedule />} />
                            <Route path="/materials" element={<Materials />} />
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;
