import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ImportantInfo = () => {
    return (
        <div>
            <Card sx={{ marginBottom: 2 }}>
                <CardContent>
                    <Typography variant="h5">Обновление учебного плана</Typography>
                    <Typography variant="body2">План на следующую неделю изменен.</Typography>
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: 2 }}>
                <CardContent>
                    <Typography variant="h5">Собрание</Typography>
                    <Typography variant="body2">В пятницу будет собрание в 12:00 в аудитории 205.</Typography>
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: 2 }}>
                <CardContent>
                    <Typography variant="h5">Практика</Typography>
                    <Typography variant="body2">Следующая практика начнется с 20 октября.</Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default ImportantInfo;
