import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BookIcon from '@mui/icons-material/Book';
import { Link } from 'react-router-dom';

const Sidebar = ({ open, onClose }) => {
    return (
        <Drawer
            variant={open ? 'temporary' : 'permanent'}
            open={open}
            onClose={onClose}
            sx={{
                width: 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 240,
                    boxSizing: 'border-box',
                },
            }}
        >
            <List>
                <ListItem button component={Link} to="/important-info">
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Важная информация" />
                </ListItem>
                <ListItem button component={Link} to="/schedule">
                    <ListItemIcon>
                        <ScheduleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Расписание" />
                </ListItem>
                <ListItem button component={Link} to="/materials">
                    <ListItemIcon>
                        <BookIcon />
                    </ListItemIcon>
                    <ListItemText primary="Материалы" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;
