import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const subjects = [
    { title: "Математика", description: "Решите уравнения из параграфа 3.1", image: "https://i.pinimg.com/564x/af/f5/6f/aff56f9dd0c3d80ee6108416ddb97ce1.jpg" },
    { title: "Физика", description: "Подготовьтесь к лабораторной работе по теме 'Механика'", image: "https://i.pinimg.com/564x/af/f5/6f/aff56f9dd0c3d80ee6108416ddb97ce1.jpg" },
];

const Materials = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Материалы
            </Typography>
            {subjects.map((subject, index) => (
                <Paper key={index} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6">{subject.title}</Typography>
                    <Typography variant="body1" paragraph>{subject.description}</Typography>
                    <img src={subject.image} alt="Example" style={{ width: "100%", maxHeight: "200px", objectFit: "cover" }} />
                </Paper>
            ))}
        </Box>
    );
};

export default Materials;
